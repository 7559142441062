<!-- 退换货申请 -->
<template>
  <page-container class="apply-for-return-container">
    <div class="order-info-container">
      <div class="order-goods-info">
        <goods-info
            view-model="small"
            :value="{
              goodsImgSrc:objectGetter(orderInfo, 'linkGoods.tagIcon') || orderInfo.thumbImageUrl,
              goodsName: orderInfo.goodsName,
              goodsDesc: brandName,
              goodsPrice: orderInfo.stockAmount,
              tagTitle: objectGetter(orderInfo, 'linkGoods.tagTitle'),
            }"
        >
          <template #price-right>
            ×{{ orderInfo.num }}
          </template>
        </goods-info>
      </div>

      <div class="order-detail-info">
        <div class="order-detail-info-item">
          <label>订单编号</label>
          <p>{{ orderInfo.orderNo }}</p>
        </div>
        <div class="order-detail-info-item">
          <label>下单时间</label>
          <p>{{ orderInfo.orderTime }}</p>
        </div>
        <div class="order-detail-info-item">
          <label>支付方式</label>
          <p>
            {{
              orderInfo.payWay == 1 ? '微信支付' : orderInfo.payWay == 2 ? '支付宝支付' : orderInfo.payWay == 3 ? '银联支付' : '余额支付'
            }}
          </p>
        </div>
        <div class="order-detail-info-item">
          <label>支付时间</label>
          <p>{{ orderInfo.payTime }}</p>
        </div>
        <div class="order-detail-info-item">
          <label>商品总额</label>
          <!--          <p>￥{{ level == 1 ? orderInfo.bidPrice : orderInfo.netPrice }}</p>-->
          <p>￥{{ orderInfo.stockAmount }}</p>
        </div>
        <div class="order-detail-info-item">
          <label>运费</label>
          <p>+￥{{ orderInfo.carriage }}</p>
        </div>
        <div class="order-detail-info-item">
          <label>实付金额</label>
          <p style="color: #000">￥{{ orderInfo.amount }}</p>
        </div>
      </div>
    </div>

<!--    <div class="form-item">-->
<!--      <p>退款方式</p>-->
<!--      <van-radio-group v-model="form.type">-->
<!--        <van-cell-group>-->
<!--          <van-cell-->
<!--              v-for="item of typeList"-->
<!--              :title="item.name"-->
<!--              clickable-->
<!--              @click="form.type = item.value"-->
<!--          >-->
<!--            <template #right-icon>-->
<!--              <van-radio :name="item.value"/>-->
<!--            </template>-->
<!--          </van-cell>-->
<!--        </van-cell-group>-->
<!--      </van-radio-group>-->
<!--    </div>-->

    <div class="form-item">
      <p>退款原因</p>
      <van-field
          v-model.trim="form.reason"
          type="textarea"
          rows="1"
          :autosize="true"
          :show-word-limit="true"
          maxlength="100"
          label-width="0"
          placeholder="请输入退款原因"
      />
    </div>

    <div v-if="returnType" class="submit-container">
      <van-button
          round
          type="primary"
          style="width: 100%"
          @click="safeguardRights"
      >
        申请售后
      </van-button>
    </div>

  </page-container>
</template>

<script>
import { GET_LOGIN_REFEREE_ID } from '@/util/storage';
import { getOrderInfoById, safeguardRights } from '@/api/normalOrder';
import { Dialog, Toast } from 'vant';
import { getUserInfo } from '@/api/user';
import GoodsInfo from '@/components/GoodsInfo';
import { CANG_KU_ZHONG, DAI_FA_HUO, DAI_SHOU_HUO, YI_WAN_CHENG } from '@/const/orderStatus';
import { getNormalGoodsInfoByIdAndRefereeId } from '@/api/normalGoods';
import { objectGetter } from '@/util';

export default {
  name: 'applyForReturn',
  components: { GoodsInfo },
  data() {
    return {
      orderInfo: { status: '' },
      userInfo: {},
      brandName:"",
      otherAttribute: undefined,
      form: {
        orderId: '',
        reason: '',
        // type: '',
      },
    };
  },
  computed: {
    // 店铺level
    level() {
      return this.userInfo.level || '';
    },
    //status
    typeList() {
      if (this.otherAttribute === undefined)
        return [];

      let { status } = this.orderInfo;
      if (!status)
        return [];
      // 02 DAI_FA_HUO 只退款
      // 09 CANG_KU_ZHONG 只退款  type 03
      let allowReturnGoodsAndMoney = [DAI_FA_HUO, CANG_KU_ZHONG].indexOf(status) < 0;
      let returnGoodsAndMoneyItem = allowReturnGoodsAndMoney ? [{ value: '01', name: '退货退款' }] : [];
      let returnMoneyItem = [
        {
          value: status === CANG_KU_ZHONG || (this.otherAttribute & (1 << 4)) > 0 ? '03' : '02',
          name: '退款',
        }
      ];
      return [...returnGoodsAndMoneyItem, ...returnMoneyItem];
    },
    returnType() {
      if (this.otherAttribute === undefined)
        return '';

      let { status } = this.orderInfo;
      if (!status)
        return '';

      // 待收货03  已收货04  只能 退货退款(01)
      if ([DAI_SHOU_HUO, YI_WAN_CHENG].includes(status))
        return '01';

      // 待发货02  只能 退款(02)
      if ([DAI_FA_HUO].includes(status))
        return '02';

      // 仓库中09 || otherAttribute ? 返回03 : 退款(02)
      return status === CANG_KU_ZHONG || (this.otherAttribute & (1 << 4)) > 0 ? '03' : '02';
    },
  },
  created() {
    getUserInfo().then(res => {
      this.userInfo = res || {};
    });

    let { orderId } = this.$route.query;
    this.form.orderId = orderId;
    this.getOrder();
  },
  methods: {
    objectGetter,
    getOrder() {
      getOrderInfoById({
        id: this.form.orderId,
        refereeId: GET_LOGIN_REFEREE_ID(),
      }).then(res => {
        this.orderInfo = {
          ...res,
          ...res.linkGoods,
        };
        this.brandName=res.brandName

        getNormalGoodsInfoByIdAndRefereeId({
          id: objectGetter(res, 'linkGoods.goodsId')
        }).then(res => {
          this.otherAttribute = res.otherAttribute;
        });
      });
    },
    //orderId、type( 01.退货退款 02.退款)、reason
    safeguardRights() {
      // if (!this.form.type)
      //   return Toast('请选择退款方式');

      if (!this.form.reason)
        return Toast('请输入退款原因');

      Dialog.confirm({
        title: `是否确申请售后么？`,
        // message: '弹窗内容',
        beforeClose: (action, done) => {
          if (action === 'confirm') {
            safeguardRights({
              ...this.form,
              type: this.returnType,
            }).then(res => {
              if (res === true) {
                this.$store.commit('orderList/setRefreshing', true);
                this.$router.back();
                done();
              }
            });
          } else {
            done();
          }
        },
      }).catch(() => {
      });
    },
  },
};
</script>

<style lang="less" scoped>
.apply-for-return-container {
  padding-bottom: 74px;

  .order-info-container {
    background-color: #fff;

    .order-goods-info {
      padding: 10px;
    }

    .order-detail-info {
      padding: 10px;

      .order-detail-info-item {
        display: flex;
        line-height: 2;
        font-size: 14px;

        & > uni-label {
          flex-shrink: 0;
          width: 70px;
          text-align: right;
          color: #333;

          &:after {
            content: ':';
          }
        }

        & > p {
          flex: 1;
          text-align: right;
          box-sizing: border-box;
          padding: 0 10px;
          color: #666;
        }
      }
    }
  }

  .form-item {
    margin: 10px;
    padding: 10px 0;
    background-color: #fff;
    border-radius: 10px;

    & > p {
      padding: 6px 0 8px 16px;
      font-size: 16px;
      color: #666;
    }
  }

  .van-hairline--top-bottom::after, .van-hairline-unset--top-bottom::after {
    border: none;
  }

  .return-reason {
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 10px;
  }

  .submit-container {
    position: fixed;
    z-index: 1;
    box-sizing: border-box;
    bottom: 0;
    left: 0;
    padding: 10px 20px 20px 20px;
    width: 100%;
    background-color: #fff;
  }

}
</style>
